<template>
  <v-row>
    <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
      <v-card class="mt-4">
        <v-card-text>
          <v-row>
            <v-col :cols="12" md="6">
              <h2 style="margin-bottom:10px">Performer: {{ studentName}}</h2>
              <v-list elevation="1">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Jury Date/Time</v-list-item-subtitle>
                    <v-list-item-title>{{ juryDate }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-avatar>{{ juryTerm }}</v-list-item-avatar>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Instructor</v-list-item-subtitle>
                    <v-list-item-title>{{ instructor }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Instrument</v-list-item-subtitle>
                    <v-list-item-title>{{ instrument }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="accompanist">
                  <v-list-item-content>
                    <v-list-item-subtitle>Accompanist</v-list-item-subtitle>
                    <v-list-item-title>{{ accompanist }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col :cols="12" md="6">
              <h2 style="margin-bottom:10px">Repertoire</h2>
              <v-list elevation="1">
                <v-list-item v-for="({ _id, name, composer }, index) of pieces" :key="'item-' + _id" :style="index > 0 ? 'border-top:1px solid lightgrey' : ''">
                  <v-list-item-content>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ composer }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>Evaluations</v-card-title>
        <v-tabs v-model="evalTab">
          <v-tab v-for="(evaluation, index) in evaluations" :key="'tab-' + index">{{ evaluation.evaluator.name }}</v-tab>
          <v-dialog v-if="isAdmin" v-model="evaluatorDialog" width="300">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" outlined style="margin:5px 0 0 10px">
                <v-icon>fal fa-plus</v-icon>
                Add An Evaluation
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Add Evaluation By</v-card-title>
              <v-card-text>
                <v-text-field v-model="evaluator" label="Evaluator"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="addEvaluation">Start Evaluation</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tabs>
        <v-tabs-items v-model="evalTab">
          <v-tab-item v-for="(evaluation, index) in evaluations" :key="'tab-content-' + index">
            <jury-evaluation :defaultEvaluation="evaluation" @save="(evl) => saveEvaluation(evl, index)"></jury-evaluation>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
export default {
  components: {
    JuryEvaluation: () => import('@/components/student/music/juryEvaluation')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => root.$store.getters.hasRole(['Technology Services', 'Employee']))
    const id = computed(() => root.$route.params.id)
    const studentName = ref('')
    const instructor = ref('')
    const instrument = ref('')
    const accompanist = ref('')
    const juryDate = ref('')
    const juryTerm = ref('')
    const pieces = ref([])
    const evalTab = ref(0)
    const evaluations = ref([])

    const service = root.$feathers.service('student/music/jury-slot')

    onMounted(async () => {
      try {
        const data = await service.get(id.value)
        if (data != null) {
          if (isAdmin.value || data.student === user.value.directoryId) {
            const student = await root.$feathers.service('directory/people').get(data.student)
            studentName.value = student.name.first + ' ' + student.name.last
            instructor.value = data.instructor
            instrument.value = data.instrument
            if ('accompanist' in data && data.accompanist != null && data.accompanist !== '') {
              accompanist.value = data.accompanist
            }
            juryDate.value = stringFormatDate(data.start)
            juryTerm.value = (data.term.substring(4, 2) === '05' ? 'Spring ' : 'Fall ') + data.term.substring(0, 4)
            pieces.value = data.pieces
            loadEvaluations(data.eval)
          } else {
            root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'You do not have access to the selected jury slot', timeout: 8000 })
            root.$router.push('student/music')
          }
        } else {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'The jury slot you attempted to access does not exist.', timeout: 8000 })
          if (isAdmin.value) root.$router.push('student/music/admin')
          else root.$router.push('student/music')
        }
      } catch (e) {
        console.log(e)
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'The jury slot you attempted to access does not exist.', timeout: 8000 })
        if (isAdmin.value) root.$router.push('student/music/admin')
        else root.$router.push('student/music')
      }
    })

    function loadEvaluations (evals) {
      evaluations.value = evals
      if (isAdmin.value) {
        for (let i = 0; i < evals.length; i++) {
          if (evals[i].evaluator.username === user.value.username) {
            break
          }
        }
      }
      if (evals.length > 1) {
        // Add a summary tab
        const summary = {
          presentation: 0,
          preparation: 0,
          persona: 0,
          progress: 0,
          potential: 0,
          comment: []
        }
        for (let i = 0; i < evals.length; i++) {
          for (const field in summary) {
            if (field === 'comment') {
              summary.comment.push({
                comment: evals[i].comment,
                name: evals[i].evaluator.name
              })
            } else {
              summary[field] += evals[i][field]
            }
          }
        }
        for (const field in summary) {
          if (field !== 'comment') summary[field] = summary[field] / evals.length
        }
        evaluations.value.splice(0, 0, { ...summary, evaluator: { name: 'Summary', username: '' } })
      }
    }

    async function saveEvaluation (evaluation, index) {
      console.log(!!evaluation.isNew, index)
      if (isAdmin.value && (evaluation.isNew || evaluation.evaluator.username === user.value.username)) {
        // Build the object to be patched
        const patch = { }
        if (evaluation.isNew) {
          delete evaluation.isNew
          patch.$push = { eval: { ...evaluation } }
        } else {
          if (evaluations.value[0].evaluator.name === 'Summary') index--
          patch['eval.' + index] = evaluation
        }
        // Patch the evaluation then update the stored list of evaluations
        try {
          const data = await service.patch(id.value, patch)
          loadEvaluations(data.eval)
          root.$store.dispatch('main/snackbar', { active: true, color: 'success', text: 'Evaluation saved', timeout: 8000 })
        } catch (e) {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'Error saving evaluation: ' + e, timeout: 8000 })
        }
      }
    }

    const evaluatorDialog = ref(false)
    const evaluator = ref('')
    function addEvaluation () {
      evaluations.value.push({
        isNew: true,
        presentation: 1,
        preparation: 1,
        persona: 1,
        progress: 1,
        potential: 1,
        comment: '',
        evaluator: {
          name: evaluator.value,
          email: user.value.email
        }
      })
      evalTab.value = evaluations.value.length - 1
      evaluatorDialog.value = false
    }

    return {
      user,
      id,
      isAdmin,
      studentName,
      instructor,
      instrument,
      accompanist,
      juryDate,
      juryTerm,
      pieces,
      evalTab,
      evaluations,
      saveEvaluation,
      evaluatorDialog,
      evaluator,
      addEvaluation
    }
  }
}
</script>
